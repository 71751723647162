var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pl-12 pr-12", attrs: { fluid: "" } },
    [
      _c(
        "ValidationObserver",
        { ref: "observer" },
        [
          _c(
            "v-layout",
            { attrs: { column: "", wrap: "" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "font-weight-black pl-3",
                  staticStyle: { "font-size": "32px" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("link.editSendTemplateTextManagement")) +
                      "\n      "
                  )
                ]
              ),
              _c("v-row", { staticClass: "ml-8" }, [
                _c(
                  "p",
                  { staticClass: "title my-auto font-weight-black" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("label.sendTemplateTextManagement.patternType")
                        ) +
                        "\n          "
                    ),
                    _c(
                      "v-chip",
                      {
                        staticClass: "ma-2 font-weight-black",
                        attrs: { color: "next", dark: "", label: "", small: "" }
                      },
                      [_vm._v(_vm._s(_vm.$t("label.required")))]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "v-row",
                { staticClass: "ml-8" },
                [
                  _c(
                    "v-flex",
                    [
                      _c("ValidationProvider", {
                        attrs: { rules: "required", immediate: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.messageTypes,
                                    "item-text": "name",
                                    required: "",
                                    "return-object": ""
                                  },
                                  model: {
                                    value: _vm.messageType,
                                    callback: function($$v) {
                                      _vm.messageType = $$v
                                    },
                                    expression: "messageType"
                                  }
                                }),
                                _c("ValidationError", {
                                  staticClass: "ma-0",
                                  attrs: { error: errors[0] }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-row", { staticClass: "ml-8" }, [
                _c(
                  "p",
                  { staticClass: "title my-auto font-weight-black" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("label.sendTemplateTextManagement.message")
                        ) +
                        "\n          "
                    ),
                    _c(
                      "v-chip",
                      {
                        staticClass: "ma-2 font-weight-black",
                        attrs: { color: "next", dark: "", label: "", small: "" }
                      },
                      [_vm._v(_vm._s(_vm.$t("label.required")))]
                    )
                  ],
                  1
                )
              ]),
              _c("v-row", { staticClass: "ml-8" }, [
                _c("p", { staticClass: "my-auto font-weight-regular" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "description.editSendTemplateTextManagement.message"
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              !_vm.isLoading && _vm.sendTemplateTextManagement
                ? _c(
                    "v-row",
                    { staticClass: "ml-8" },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              rules:
                                "required|requiredContractUrl|unusableModificationContent|max:" +
                                _vm.maxLengthMessage,
                              immediate: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("v-textarea", {
                                        attrs: {
                                          rows: "20",
                                          outlined: "",
                                          required: "",
                                          counter: _vm.maxLengthMessage
                                        },
                                        model: {
                                          value:
                                            _vm.sendTemplateTextManagement
                                              .message,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.sendTemplateTextManagement,
                                              "message",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "sendTemplateTextManagement.message"
                                        }
                                      }),
                                      _c("ValidationError", {
                                        staticClass: "ma-0",
                                        attrs: { error: errors[0] }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              207401965
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.messageType.senderType === "sms"
                ? [
                    _c(
                      "v-card-text",
                      {
                        staticClass:
                          "font-weight-black text-right pr-2 pb-1 mx-auto"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "description.messageContent.sendMessageFooter.sms"
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                : _vm._e(),
              _vm.messageType.senderType === "mail"
                ? [
                    _c(
                      "v-card-text",
                      {
                        staticClass:
                          "font-weight-black text-right pr-2 pb-1 mx-auto"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "description.messageContent.sendMessageFooter.mail"
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                : _vm._e(),
              _c(
                "v-card-text",
                { staticClass: "text-right pt-0 pr-2 pb-1 mx-auto" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t(
                          "description.messageContent.sendMessageFooter.contractUrl"
                        )
                      ) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "v-row",
                { staticClass: "ml-8 mt-4" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "title mr-5",
                      staticStyle: { "font-size": "15px !important" },
                      attrs: { color: "back", width: "170px", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.onClickBackHome()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")) + "\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "title ml-5",
                      staticStyle: { "font-size": "15px !important" },
                      attrs: { color: "next", width: "170px", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.onClickUpdate()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.register")) + "\n        ")]
                  )
                ],
                1
              ),
              _c("CompletedDialog", { ref: "completedDialog" }),
              _c("ErrorDialog", { ref: "errorDialog" })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }